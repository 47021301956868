import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Link,
  Textarea,
  useToast,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Image,
  ModalHeader,
} from '@chakra-ui/react';

import { AttachmentIcon } from '@chakra-ui/icons';
import { GET, POST } from '../../../utilities/ApiProvider';
import { useState } from 'react';
import LogoIcon from '../../../assets/images/website/logoIcon.jpg';
import { Country, City } from 'country-state-city';

import { PhoneInput } from 'react-international-phone';
// import { Link } from 'react-router-dom';
function AddPatient() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const toast = useToast();
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const form = new FormData(e.target);
      if (form.get('password') !== form.get('confirmPassword')) {
        toast({
          description: 'Password and Confirm Password do not match',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        return;
      }
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
      const response = await POST('/authentication/patient', form);

      console.log(response);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Patient created Sucessfully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        e.target.reset();
        setSelectedFile(null);
      } else {
        toast({
          description: response?.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    }
  };
  const handleCountryChange = e => {
    const countryCode = e.target.value?.split('-')[1].trim();

    const fetchedCities = City.getCitiesOfCountry(countryCode);
    console.log(fetchedCities, countryCode);
    setCities(fetchedCities);
  };
  useEffect(() => {
    const countryData = Country.getAllCountries().map(country => ({
      value: country.isoCode,
      displayValue: `${country.name} - ${country.isoCode}`,
    }));
    setCountries(countryData);
  }, []);

  return (
    <Stack>
      <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'12px'}>
          <ModalCloseButton />
          <ModalBody p="25px" className="modalBody">
            <Image src={LogoIcon} display={'flex'} mx="auto" />
            <Heading
              fontSize={'26px'}
              fontWeight={'500'}
              my="15px"
              transition={'.3s'}
              _groupHover={{ color: 'primaryBlack.100' }}
            >
              Terms & Conditions:
            </Heading>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              Thank you for choosing emedii.com as an intermediary to book the
              treatment services you need that are provided by hospitals through
              us, as well as to introduce you to the health practitioners who
              list their professional information and experience for you to
              review and choose the best that suits your needs.
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              These terms and conditions may be amended from time to time;  they
              apply directly or indirectly (through distributors) to all our
              services available through our website, our platform, and any of
              our means. By completing the booking of any of our services, you
              decide and agree that you have read, understood, and agreed to the
              terms and conditions set forth below including the privacy policy,
              and that you are legally eligible to comply with the following: -
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              (1)   The above preamble forms an integral part of the terms and
              conditions and privacy statement.
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              (2)   Definitions
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.1 emedii.com: A platform and brokerage website for marketing a
              group of services in the health sector, aiming to provide the
              service to three categories (patients - hospitals - health
              practitioners). It belongs to the company (...) and its address is
              (……)
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.2 Patient, Visitor or User of the platform and website: Everyone
              who has been able to register with the platform and website and
              use its services provided by the hospitals through which they
              provide their services.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.3 Password and Username: These mean, respectively, a unique
              password and name issued by (emedii.com) to clients who use the
              platform and the site.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.4 Customer Data: This means all information collected about the
              customer, including his personal data.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.5 Data Protection: This means all laws, regulations, and any
              other legislation issued and in force to regulate the privacy,
              processing, and obtaining of personal information.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.6 Treatment Services: These mean the medical care provided by
              hospitals specializing in the treatment of certain diseases that
              require advanced equipment and are provided by highly specialized
              doctors.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.7 Data and Information Panel (Dashboard): This means the data
              and information related to each hospital and health practitioner
              that offers its services on the platform.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.8 Timetables: A pre-prepared model for clinic appointments
              generated by emedii.com. The hospital builds it with the
              appointments available to its doctors and according to which
              patients can book their appointments.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Register New Patients
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Enter the patient details to add
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Box
          w="100%"
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Box w="100%" mb="10px">
            <FormLabel>Fist Name</FormLabel>
            <Input name="fullName" placeholder="Enter here" />
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Family Name</FormLabel>
            <Input name="familyName" placeholder="Enter here" />
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Age</FormLabel>
            <Input type="number" name="age" placeholder="Enter here" />
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Email</FormLabel>
            <Input name="email" placeholder="Enter here" />
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Phone No.</FormLabel>
            <PhoneInput
              name="number"
              required
              containerStyle={{ background: 'none', border: 'none' }}
              inputStyle={{ background: 'none', border: 'none' }}
              dropdownStyle={{ background: 'none', border: 'none' }}
              id="phone-input"
              defaultCountry="sa"
              inputClass="custom-phone-input"
            />
          </Box>
          <Box width={'100%'} mb="10px">
            <FormLabel>Country</FormLabel>
            <Select
              name="country"
              onChange={handleCountryChange}
              placeholder="Select option"
            >
              {countries.map(country => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.displayValue}
                </option>
              ))}
            </Select>
          </Box>
          <Box width={'100%'} mb="10px">
            <FormLabel>City</FormLabel>
            <Select name="city" placeholder="Select option">
              {cities.map(city => (
                <option
                  key={city?.name?.toLowerCase()}
                  value={city?.name?.toLowerCase()}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Gender</FormLabel>
            <Select name="gender" placeholder="Choose here">
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Select>
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>password</FormLabel>
            <Input name="password" type="password" placeholder="Enter here" />
          </Box>
          <Box w="100%" mb="10px">
            <FormLabel>Confirm password</FormLabel>
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Enter here"
            />
          </Box>
          <Box w="100%" mb={'10px'}>
            <Box w="100%" mb="10px">
              <HStack alignItems="center" py="10px">
                <Checkbox
                  colorScheme="blue"
                  onChange={e => setAgreeToTerms(e.target.checked)}
                ></Checkbox>
                <Text fontSize="14px" color="primaryGray.100">
                  I agree with{' '}
                  <Link
                    color="blue.500"
                    cursor={'pointer'}
                    textDecoration="underline"
                    onClick={onOpen}
                  >
                    "Terms & Conditions"
                  </Link>
                </Text>
              </HStack>
            </Box>
          </Box>
          {/* <Box w="100%">
            <FormLabel>Photo</FormLabel>
            <Input
              type="file"
              id="file-input"
              required
              display="none"
              onChange={handleFileChange}
            />
            <Text fontSize="14px" color="primaryGray.100" textAlign={'right'}>
              <Button
                cursor={'pointer'}
                as="span"
                border="1px solid #75767A"
                mb="10px"
                bgColor="transparent"
                fontSize="14px"
                w="100%"
                color="primaryGray.100"
                transition=".3s"
                _hover={{
                  bgColor: 'primaryGreen.200',
                  color: '#fff',
                  borderColor: 'primaryGreen.200',
                }}
                mr="10px"
                onClick={handleButtonClick}
              >
                <AttachmentIcon mr="10px" /> Attach File
              </Button>
              {selectedFile ? selectedFile.name : 'No File chosen'}
            </Text>
          </Box> */}
          <Box w="100%">
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              h="45px"
              px="80px"
              w={'100%'}
              type="submit"
              isLoading={loading}
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
              disabled={!agreeToTerms}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </form>
    </Stack>
  );
}

export default AddPatient;

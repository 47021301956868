import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  HStack,
  VStack,
  Box,
  IconButton,
  Avatar,
  Badge,
  Button,
  useDisclosure,
  FormLabel,
  Textarea,
  Checkbox,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment/moment';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PatientsList() {
  const [selectedService, setSelectedService] = useState('pending');
  const user = useSelector(state => state.user.value);
  const [type, setType] = useState(user?.type);
  const query = useQuery();
  const paramsType = query.get('type');
  const [listOfPatients, setListOfPatients] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [patients, setPatients] = useState(null);
  const [selectedId, setId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function fetchPatients() {
    try {
      const response = await GET(
        paramsType ? `/web/patients?type=${paramsType}` : '/web/patients'
      );
      console.log('patients', response.data);
      if (response.status === 200) {
        setPatients(response.data);
      } else setPatients([]);
    } catch (error) {
      console.error('An error occurred while fetching patients:', error);
    }
  }
  useEffect(() => {
    setType(user?.type);
    if (user?.type === 'practitioner') setSelectedService('onGoing');
  }, [user]);
  useEffect(() => {
    fetchPatients();
  }, []);

  return (
    <Stack>
      {/*Heading  */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Patients List
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={{ base: '5px', xl: '20px' }}
      >
        {type !== 'practitioner' && (
          <Button
            bgGradient={
              selectedService === 'pending'
                ? 'linear(to-r, #295377, #208C74)'
                : 'linear(to-b, #C2D4FF, #fff)'
            }
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={selectedService === 'pending' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '35px' }}
            onClick={() => setSelectedService('pending')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Pending
          </Button>
        )}
        <Button
          bgGradient={
            selectedService === 'onGoing'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'onGoing' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('onGoing')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Ongoing
        </Button>
      </Stack>
      {!patients ? (
        <Spinner />
      ) : (
        <Stack display={'flex'} flexDirection={'column'} gap={2}>
          <HStack display={'flex'} w="100%" px={4}>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              NO.
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              ID
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={2}>
              Patient Name
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              Age
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={2}>
              Appointment Date
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              patient Type
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              Status
            </Box>
            <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
              Action
            </Box>
          </HStack>
          {/* {patients.length > 0 ? } */}
          {patients?.[selectedService]?.length > 0 ? (
            patients?.[selectedService]?.map((val, ind) => (
              <>
                <HStack
                  key={val._id}
                  display={'flex'}
                  w="100%"
                  h="70px"
                  bg={'#fff'}
                  shadow={'0px 16px 38px 0px #0000000F'}
                  px={4}
                  borderRadius={'16px'}
                >
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {ind + 1}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    #{val?._id?.slice(-5)}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={2}
                  >
                    <VStack
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                      gap={2}
                    >
                      <Avatar
                        size="sm"
                        name="Kent Dodds"
                        src={`${imgUrl}/${val?.patientPicture}`}
                      />
                      <Text> {val?.patientName}</Text>
                    </VStack>
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {val?.patientage || '27'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={2}
                  >
                    {moment(val.startTime).format('M/D/YYYY') || '2/27/2024'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {val.type || 'ECG'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <Badge
                      w="81px"
                      height="28px"
                      display={'flex'}
                      alignItems={'center'}
                      borderRadius={'50px'}
                      justifyContent={'center'}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      bg={selectedService === 'pending' ? '#F3D893' : '#F3B693'}
                      color={
                        selectedService === 'pending' ? '#C79209' : '#B85923'
                      }
                      textTransform={'capitalize'}
                    >
                      {selectedService === 'pending' ? 'Pending' : 'Ongoing'}
                    </Badge>
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <HStack>
                      {' '}
                      {/* {type !== 'superadmin' && (
                        <IconButton
                          onClick={() => {
                            setId(val._id);
                            onOpen();
                          }}
                          isRound={true}
                          variant="solid"
                          colorScheme="#D9D9D9"
                          w={'40px'}
                          h={'40px'}
                          fontSize="16px"
                          bgGradient="linear(to-r, #295377, #208C74)"
                          bg={'#D9D9D9'}
                          aria-label="Done"
                          icon={<EditIcon fontSize="16px" color="#1B1C1D" />}
                        />
                      )} */}
                      <IconButton
                        isRound={true}
                        variant="solid"
                        as={Link}
                        to={`/dashboard/patient-profile/${val?._id}`}
                        colorScheme="#F2F2F2"
                        w={'40px'}
                        h={'40px'}
                        fontSize="16px"
                        bgGradient="linear(to-r, #295377, #208C74)"
                        aria-label="Done"
                        icon={<ViewIcon fontSize="16px" color="#F2F2F2" />}
                      />
                    </HStack>
                  </Box>
                </HStack>
              </>
            ))
          ) : (
            <Heading>No patient found</Heading>
          )}
        </Stack>
      )}

      <BasicUsage
        isOpen={isOpen}
        onClose={onClose}
        title={'Re-schedule Appointment'}
        subTitle={'Enter the details to add new service'}
        children={<RescheduleAppointment id={selectedId} onClose={onClose} />}
      />
    </Stack>
  );
}

function RescheduleAppointment({ id, onClose }) {
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const [reason, setReason] = useState('');
  const toast = useToast();
  const handleDaySelection = index => {
    setSelectedDayIndex(index);
  };

  const handleRequestSend = async () => {
    const selectedDay = openingHours[selectedDayIndex];
    try {
      const response = await POST(`/hospital/appointments/${id}/reschedule`, {
        reasons: reason,
        availability: [selectedDay],
      });
      if (response.status === 200) {
        toast({
          title: 'Rescheduled successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log('Erorr while rescheduling', error);
    }
  };
  return (
    <>
      <form className="applyForm">
        <Box mb="15px !important">
          <FormLabel>Reason of reschedule</FormLabel>
          <Textarea
            onChange={e => setReason(e.target.value)}
            resize={'none'}
            placeholder="Here is a sample placeholder"
          />
        </Box>
        <Box mb="10px">
          <FormLabel>Choose Availability</FormLabel>
          {openingHours.map((val, index) => (
            <Stack
              border={'2px solid'}
              borderRadius={'12px'}
              borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
              p="5px 5px 5px 15px"
              key={val.day}
              direction={'row'}
              alignItems={'center'}
              mb="0px !important"
            >
              <Text flex={'1'}>
                <Checkbox
                  colorScheme="teal"
                  defaultChecked={val?.on}
                  isChecked={selectedDayIndex === index}
                  onChange={() => handleDaySelection(index)}
                  // onChange={e => changeOnStatus(ind, e.target.checked)}
                  mr="5px"
                  mt="3px"
                  transform="translateY(2px)"
                ></Checkbox>
                {val.day}:
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeStartingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.startTime}
                  value={val.startTime}
                />
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeEndingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.endTime}
                  value={val.endTime}
                />
              </Text>
            </Stack>
          ))}
        </Box>
        <Box mb="15px !important">
          <Button
            w="100%"
            onClick={handleRequestSend}
            mt={'10px'}
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
          >
            Send Request
          </Button>
        </Box>
      </form>
    </>
  );
}

function BasicUsage({ isOpen, onClose, title, subTitle, children }) {
  return (
    <>
      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader margin={'0 auto'} fontSize={'26px'} fontWeight={'500'}>
            {title}
            <Text
              color={'#75767A'}
              textAlign={'center'}
              fontSize={'14px'}
              fontWeight={'400'}
            >
              {subTitle}
            </Text>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

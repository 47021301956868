import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Image,
  Icon,
  useToast,
  Select,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { FaRegHeart } from 'react-icons/fa';
import { CiLocationOn } from 'react-icons/ci';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { Link } from 'react-router-dom';
import { Country, City } from 'country-state-city';

function AvailableHospitals() {
  const [hospitals, setHospitals] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  async function fetchHospitals(filter = '') {
    try {
      const response = await GET(
        `/web/patient/hospital?${filter}&skip=0&limit=40`
      );
      console.log(response, 'availablehospitals');

      if (response.status === 200) {
        console.log(response.data);
        setHospitals(response?.data?.serviceData);
        // setHospitals(response?.data?.all.sort((a, b) => b.rating - a.rating));
      }
    } catch (error) {
      console.log('Error while fetching hospitals', error);
    }
  }
  const handleFavourite = async id => {
    try {
      const response = await POST('/web/patient/favourite', {
        type: 'hospital',
        hospital: id,
      });
      if (response.status === 200) {
        fetchHospitals();
      }
    } catch (error) {
      console.log('Error while fetching hospitals', error);
    }
  };
  const handleCountryChange = e => {
    const countryCode = e.target.value?.split('-')[1].trim();

    const fetchedCities = City.getCitiesOfCountry(countryCode);
    console.log(fetchedCities, countryCode);
    setCities(fetchedCities);
  };
  useEffect(() => {
    const countryData = Country.getAllCountries().map(country => ({
      value: country.isoCode,
      displayValue: `${country.name} - ${country.isoCode}`,
    }));
    setCountries(countryData);
    fetchHospitals();
  }, []);
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let filter = '';
      const form = new FormData(e.target);
      console.log(Array.from(form.entries()));

      if (form.get('hospitalCategory')) {
        filter += `hospitalCategory=${encodeURIComponent(
          form.get('hospitalCategory')
        )}`;
      }
      if (form.get('searchQuery')) {
        if (filter) filter += '&';
        filter += `searchQuery=${encodeURIComponent(form.get('searchQuery'))}`;
      }
      if (form.get('country')) {
        if (filter) filter += '&';
        filter += `country=${encodeURIComponent(form.get('country'))}`;
      }
      if (form.get('city')) {
        if (filter) filter += '&';
        filter += `city=${encodeURIComponent(form.get('city'))}`;
      }

      await fetchHospitals(filter);
    } catch (error) {
      console.log(error);
    }
  };
  const clearFilter = e => {
    setHospitals(null);
    fetchHospitals();
    document.getElementById('filers').reset();
  };

  return (
    <>
      <Stack mt={'10px !important'}>
        <Text fontSize={'18px'} fontWeight={500}>
          {hospitals?.length || 0} hospitals found
        </Text>
      </Stack>

      <form id="filers" onSubmit={handleSubmit}>
        <Stack
          className="applyForms"
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          py={4}
          gap={4}
        >
          <Box w={'100%'}>
            <FormLabel>Hospital Category*</FormLabel>
            <Select name="hospitalCategory" placeholder="Choose here">
              <option value="general hospital"> General Hospital</option>
              <option value="specialist hospital"> Specialist Hospital</option>
              <option value="tertiary hospital"> Tertiary Hospital</option>
              <option value="medical center">Medical Center</option>
              <option value="clinics complex">Clinics Complex</option>
            </Select>
          </Box>
          <Box width={'100%'}>
            <FormLabel>Search by Hospital Name</FormLabel>
            <Input name="searchQuery" placeholder="Enter SubSpecialist" />
          </Box>
          <Box width={'100%'}>
            <FormLabel>Country</FormLabel>
            <Select
              name="country"
              onChange={handleCountryChange}
              placeholder="Select option"
            >
              {countries.map(country => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.displayValue}
                </option>
              ))}
            </Select>
          </Box>
          <Box width={'100%'}>
            <FormLabel>City</FormLabel>
            <Select name="city" placeholder="Select option">
              {cities.map(city => (
                <option
                  key={city?.name?.toLowerCase()}
                  value={city?.name?.toLowerCase()}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              border={'1px solid'}
              color={'#fff'}
              borderRadius={'25px'}
              padding={'20px 35px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
              type="submit"
            >
              Search
            </Button>
          </Box>
          <Box>
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              border={'1px solid'}
              color={'#fff'}
              borderRadius={'25px'}
              padding={'20px 35px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
              onClick={e => clearFilter(e)}
            >
              Clear filter
            </Button>
          </Box>
        </Stack>
      </form>
      {!hospitals && <Spinner />}
      {hospitals?.length === 0 && (
        <Text fontSize={'18px'} fontWeight={500}>
          No hospitals found
        </Text>
      )}
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={6}>
        {hospitals?.map(hospital => (
          <Box
            shadow={'0px 16px 38px 0px #0000000F'}
            bg={'#fff'}
            borderRadius={'16px'}
            p={4}
            display={'flex'}
            flexDirection={'column'}
            gap={4}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              px={4}
            >
              <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                <Avatar
                  size={'lg'}
                  name="Dan Abrahmov"
                  // src={`${imgUrl}/${
                  //   selectedService === 'patient' ? item.picture : item.photo
                  // }`}
                  src={
                    `${imgUrl}/${hospital?.banner}` ||
                    'https://bit.ly/dan-abramov'
                  }
                />
                <Stack>
                  <Heading fontSize={'22px'} fontWeight={'500'}>
                    {hospital?.name}
                  </Heading>
                  <HStack alignItems={'center'}>
                    <Icon as={CiLocationOn} w="20px" h="20px" color="#75767A" />

                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      {hospital?.address}
                    </Text>
                  </HStack>

                  <Box w="100%">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Text fontSize="15px" color="#75767A" fontWeight="400">
                        {hospital?.rating > 0 ? `${hospital?.rating}.0` : '0'}
                      </Text>

                      {Array.from({ length: 5 }, (_, i) => (
                        <Icon
                          as={i < hospital?.rating ? FaStar : FaRegStar}
                          color={'#FEB052'}
                          key={i}
                        />
                      ))}
                      <Text fontSize="15px" color="#75767A" fontWeight="400">
                        {`(${hospital?.reviews} reviews)`}
                      </Text>
                    </Stack>
                  </Box>
                </Stack>
              </HStack>
              <Stack
                onClick={() => handleFavourite(hospital?._id)}
                cursor={'pointer'}
              >
                <Icon
                  as={hospital?.isFavorite ? FaHeart : FaRegHeart}
                  w="27px"
                  h="25px"
                  color="#208C74"
                />
              </Stack>
            </Stack>
            <Stack pt={4}>
              <Button
                w="100%"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
                as={Link}
                to={`/dashboard/patient-booking/${hospital?._id}`}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        ))}
      </Grid>
    </>
  );
}

export default AvailableHospitals;

import React from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';

export default function PatBooking({ data }) {
  return (
    <>
      <SimpleGrid
        columns={{ base: '1', md: '2', xl: '2', '2xl': '3' }}
        spacing={10}
      >
        {data?.length > 0 &&
          data?.map((v, k) => {
            return (
              <Box key={k} borderRadius={'16px'} p={4} shadow={'lg'}>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Heading fontSize={'20px'} fontWeight={'500'}>
                    Bed Booking Details
                  </Heading>
                  <Heading
                    fontSize={'20px'}
                    color={'#208C74'}
                    fontWeight={'500'}
                  >
                    {v?.bed?.name}
                  </Heading>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  lineHeight={'0.6rem'}
                  mt={3}
                  gap={2}
                >
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Patient Name
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.name}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Age
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.age}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Sex
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.gender}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Patient Location
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.location}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Desired Inpatient Department
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.department?.name}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Type of Bed
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.typeOfBed}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Bed Name
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.bed?.name}
                    </Text>
                  </Stack>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Start Date
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.startDate}
                    </Text>
                  </Stack>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      End Date
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.endDate}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Desired Hospitalist
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.hospitalist}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Treatment Cost to Be Paid by
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.costtobepaid}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            );
          })}
      </SimpleGrid>
    </>
  );
}

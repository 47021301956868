import {
  Heading,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  FormLabel,
  Input,
  Button,
  IconButton,
  HStack,
  Avatar,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';
import { ViewIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { imgUrl } from '../../../utilities/config';
import RequestElectronicDetails from './RequestElectronicDetails';
import RequestAdmissionMedicalDetails from './RequestAdmissionMedicalDetails';

function FormRequestDetail() {
  const toast = useToast();
  const user = useSelector(state => state?.user?.value);
  const navigate = useNavigate();
  const [service, setService] = useState({});
  const { id } = useParams();
  const [modalData, setmodalData] = useState({});
  // const [formValue, setformValue] = useState({
  //   name: '',
  //   address: '',
  //   email: '',
  //   requestingStaff: '',
  //   number: '',
  //   practitionerSpecialty: '',
  //   practitionerClassificationDegree: '',
  //   practitionerAcademicQualifications: '',
  //   practitionerYearsOfExperience: '',
  //   practitionerSex: '',
  //   practitionerNationality: '',
  //   contractType: '',
  // });
  const [formValue, setformValue] = useState(null);

  const [details, setDetails] = useState(null);

  const getJobDetails = async () => {
    try {
      const response = await GET(`/admin/form-requests/detail/${id}`);
      console.log(response?.data);
      if (response.status === 200) {
        setService(response?.data?.service);
        setDetails(response?.data?.requests);
        let abc = JSON.parse(response.data?.requests.completeData);
        setformValue(abc);
        console.log('abc', abc);
      } else {
        setDetails({});
        console.log('error', response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getJobDetails();
  }, [id]);
  const handleAcceptDecline = async (id, type) => {
    try {
      const response = await PUT(`/admin/jobs/${id}`, {
        type,
      });
      console.log('response', response);
      if (response.status === 200) {
        toast({
          description: `Job has been ${type}`,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
        navigate('/dashboard/jobs');
      } else {
        console.log('error', response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  if (!formValue) {
    return (
      <Stack h={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Spinner />
      </Stack>
    );
  }
  return (
    <Stack gap={10}>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'28px'}
          fontWeight={'500'}
        >
          {service?.name} {''}/
          <Text
            mt={'8px'}
            ml={'2px'}
            color={'#208C74'}
            fontSize={'20px'}
            fontWeight={'500'}
          >
            Jobs
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <Stack>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem border={'none'}>
            <AccordionButton
              _hover={{
                bg: '#fff',
              }}
            >
              <Box as="span" flex="1" textAlign="left">
                <Heading
                  display={'flex'}
                  alignItems={'center'}
                  fontSize={'24px'}
                  fontWeight={'500'}
                >
                  View Job Details
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={6}>
              {service?.name ===
              'Request Electronic Medical Article, Research, Magazine or Book' ? (
                <RequestElectronicDetails formValue={formValue} />
              ) : service?.name ===
                'Request for Admission to a Medical Education and Training Program' ? (
                <RequestAdmissionMedicalDetails formValue={formValue} />
              ) : (
                <Stack>
                  <form className="applyForm">
                    <Stack
                      w="100%"
                      flexDirection={'row'}
                      alignItems={'center'}
                      gap={4}
                      flexWrap={'wrap'}
                    >
                      <Box w="49%">
                        <FormLabel>Name</FormLabel>
                        <Input
                          value={formValue.name}
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Box>
                      <Box w="49%">
                        <FormLabel>Address</FormLabel>
                        <Input
                          value={formValue.address}
                          name="address"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Box>
                      <Box w="49%">
                        <FormLabel>Email Address</FormLabel>
                        <Input
                          value={formValue.email}
                          name="email"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Box>
                      <Box w="49%">
                        <FormLabel>Requesting Staff</FormLabel>
                        <Input
                          value={
                            formValue.requestingStaff ||
                            formValue.requestingStaffName
                          }
                          name="requestingStaff"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Box>
                      <Box w="49%">
                        <FormLabel>Mobile No.</FormLabel>
                        <Input
                          value={formValue.number}
                          name="number"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Box>
                    </Stack>
                    {service?.name ===
                      'Health Practitioners Recruitment Request by a Hospital' && (
                      <>
                        <Text fontWeight={'500'} fontSize={'20px'} py={5}>
                          Particulars of Requested Health Practitioners:
                        </Text>
                        <Stack
                          w="100%"
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap={4}
                          flexWrap={'wrap'}
                        >
                          <Box w="49%">
                            <FormLabel>Specialty</FormLabel>
                            <Input
                              name="practitionerSpecialty"
                              value={formValue.practitionerSpecialty}
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="49%">
                            <FormLabel>
                              Professional Classification Degree
                            </FormLabel>
                            <Input
                              value={formValue.practitionerClassificationDegree}
                              name="practitionerClassificationDegree"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="49%">
                            <FormLabel>Academic Qualifications</FormLabel>
                            <Input
                              value={
                                formValue.practitionerAcademicQualifications
                              }
                              name="practitionerAcademicQualifications"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="49%">
                            <FormLabel>Years of Experience</FormLabel>
                            <Input
                              value={formValue.practitionerYearsOfExperience}
                              name="practitionerYearsOfExperience"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="49%">
                            <FormLabel>Sex</FormLabel>
                            <Input
                              value={formValue.practitionerSex}
                              name="practitionerSex"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="49%">
                            <FormLabel>Nationality</FormLabel>
                            <Input
                              value={formValue.practitionerNationality}
                              name="practitionerNationality"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                        </Stack>
                        <Text fontWeight={'500'} fontSize={'20px'} py={5}>
                          Contract Type:
                        </Text>
                        <Stack
                          spacing={0}
                          gap={4}
                          direction={'row'}
                          flexDir={{ base: 'column', xl: 'row' }}
                          justifyContent={'space-between'}
                        >
                          <Box w="49%">
                            <FormLabel>Type</FormLabel>
                            <Input
                              value={formValue.advertisementDisplayMeans}
                              name="contractType"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </Box>
                        </Stack>
                      </>
                    )}
                    {service?.name ===
                      'Medical Library Services Request for a Hospital' && (
                      <>
                        <Text fontWeight={'500'} fontSize={'20px'}>
                          Advertisement Display Means:
                        </Text>

                        <Stack pos={'relative'}>
                          <FormLabel>Choose an option</FormLabel>
                        </Stack>

                        <Box w="100%" className="checkboxFamily">
                          <Stack
                            spacing={0}
                            gap={4}
                            direction={'row'}
                            flexDir={{ base: 'column', xl: 'row' }}
                            flexWrap={'wrap'}
                          >
                            <Box w="32.33%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  formValue.advertisementDisplayMeans.medical
                                }
                                // defaultChecked={checkedValues.medical}
                              >
                                {' '}
                                Medical Databases
                              </Checkbox>
                            </Box>
                            <Box w="32.33%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  formValue.advertisementDisplayMeans.articles
                                }
                                // defaultChecked={checkedValues.articles}
                                // onChange={handleCheckboxChange}
                              >
                                Articles
                              </Checkbox>
                            </Box>
                            <Box w="32.33%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  formValue.advertisementDisplayMeans.research
                                }
                                // defaultChecked={checkedValues.research}
                                // onChange={handleCheckboxChange}
                              >
                                Researches
                              </Checkbox>
                            </Box>
                            <Box w="32.33%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  formValue.advertisementDisplayMeans
                                    .periodicals
                                }
                                // defaultChecked={checkedValues.periodicals}
                              >
                                Periodicals
                              </Checkbox>
                            </Box>
                            <Box w="32.33%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  formValue.advertisementDisplayMeans.reference
                                }
                                // defaultChecked={checkedValues.reference}
                              >
                                Digital Medical References
                              </Checkbox>
                            </Box>
                          </Stack>
                        </Box>
                      </>
                    )}
                    {service?.name ===
                      'Electronic Advertisement Request for a Hospital' && (
                      <>
                        <Text fontWeight={'500'} fontSize={'20px'}>
                          Advertisement Information:
                        </Text>
                        <Stack
                          w="100%"
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap={4}
                        >
                          <Box w="100%">
                            <FormLabel>Type of Advertisement</FormLabel>
                            <Input
                              required
                              name="advertisementType"
                              type="text"
                              value={formValue.advertisementType}
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="100%">
                            <FormLabel>Advertisement Duration</FormLabel>
                            <Input
                              required
                              name="advertisementDuration"
                              type="text"
                              value={formValue.advertisementDuration}
                              placeholder="Enter Name"
                            />
                          </Box>
                          <Box w="100%">
                            <FormLabel>Advertisement Display Time</FormLabel>
                            <Input
                              required
                              name="advertisementDisplayTime"
                              type="text"
                              value={formValue.advertisementDisplayTime}
                              placeholder="Enter Name"
                            />
                          </Box>
                        </Stack>

                        <Text fontWeight={'500'} fontSize={'20px'}>
                          Advertisement Display Means:
                        </Text>
                        <Stack pos={'relative'}></Stack>
                        <Box
                          name="advertisementdisplayMeans"
                          w="100%"
                          className="checkboxFamily"
                        >
                          <Stack
                            spacing={0}
                            gap={4}
                            direction={'row'}
                            flexDir={{ base: 'column', xl: 'row' }}
                            justifyContent={'space-between'}
                          >
                            <Box w="100%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  JSON.parse(
                                    formValue.advertisementDisplayMeans
                                  ).website
                                }
                                // defaultChecked={checkedValues.website}
                                // onChange={handleCheckboxChange}
                              >
                                emedii.com website
                              </Checkbox>
                            </Box>
                            <Box w="100%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  JSON.parse(
                                    formValue.advertisementDisplayMeans
                                  ).socialMedia
                                }
                                // defaultChecked={checkedValues.socialMedia}
                                // onChange={handleCheckboxChange}
                              >
                                emedii.com social media
                              </Checkbox>
                            </Box>
                            <Box w="100%">
                              <Checkbox
                                colorScheme="teal"
                                isChecked={
                                  JSON.parse(
                                    formValue.advertisementDisplayMeans
                                  ).emails
                                }
                                // defaultChecked={checkedValues.emails}
                                // onChange={handleCheckboxChange}
                              >
                                emails by emedii.com to clients
                              </Checkbox>
                            </Box>
                          </Stack>
                        </Box>
                      </>
                    )}

                    {/* <Stack
                    w={'48%'}
                    display={'flex'}
                    justifyContent={'center'}
                    direction={'row'}
                    pt={2}
                  >
                    <Button
                      width={'90%'}
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      onClick={() => handleAcceptDecline(id, 'accepted')}
                    >
                      Accept
                    </Button>
                    <Button
                      width={'90%'}
                      bg={'#FBFBFB'}
                      border={'1px solid #1B1C1D'}
                      color="#1B1C1D"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      _hover={{
                        bgGradient: '#FBFBFB',
                        borderColor: '#1B1C1D',
                        color: '#1B1C1D',
                      }}
                      onClick={() => handleAcceptDecline(id, 'rejected')}
                    >
                      Decline
                    </Button>
                  </Stack> */}
                  </form>
                </Stack>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </Stack>
  );
}

export default FormRequestDetail;

import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AppointmentBox from './AppointmentBox';
import { GET } from '../../../utilities/ApiProvider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PatBooking from '../Services/BedBooking/PatBooking';

function DashboardAppointments() {
  const [selectedService, setSelectedService] = useState('pending');
  const [followup, setfollowup] = useState('virtual');
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(state => state.user.value);
  const [type, setType] = useState(user?.type);
  useEffect(() => {
    setType(user?.type);
    if (user?.type === 'practitioner') setSelectedService('ongoing');
  }, [user]);
  const [appointments, setAppointments] = useState(null);

  async function fetchAppointments() {
    setIsLoading(true);
    try {
      const response = await GET('/web/appointments');
      if (response.status === 200) {
        setAppointments(response.data);
        setIsLoading(false);
      } else {
        setAppointments([]);
      }
    } catch (error) {
      console.log('Error : ', error);
    }
  }
  useEffect(() => {
    fetchAppointments();
  }, []);

  const handleFollowupChange = async type => {
    setfollowup(type);
    setIsLoading(true);
    try {
      // API URL based on the selected follow-up type
      const apiUrl = `/web/appointments/${type}`;

      // Fetch the data using GET
      const response = await GET(apiUrl);

      if (response.status === 200) {
        console.log('Success:', response.data);
        setAppointments(response.data);
        setIsLoading(false);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Automatically hit "virtual" endpoint when the page loads
  useEffect(() => {
    handleFollowupChange('virtual');
  }, []); // Empty dependency array to run only on mount

  return (
    <Stack gap={6}>
      <Stack gap={4}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Follow Up
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest Follow Up...Check Now!
          </Text> */}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
          {type === 'patient' && (
            <>
              <Button
                bgGradient={
                  followup === 'virtual' && 'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={followup === 'virtual' ? '#fff' : 'primaryBlack.100'}
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => {
                  handleFollowupChange('virtual');
                  setSelectedService('pending');
                }}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                Virtual
              </Button>
              <Button
                bgGradient={
                  followup === 'inperson' && 'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={followup === 'inperson' ? '#fff' : 'primaryBlack.100'}
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => {
                  handleFollowupChange('inperson');
                  setSelectedService('pending');
                }}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                In Person
              </Button>
              <Button
                bgGradient={
                  followup === 'bedbooking' && 'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={followup === 'bedbooking' ? '#fff' : 'primaryBlack.100'}
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => {
                  handleFollowupChange('bedbooking');
                  setSelectedService('bedBooking');
                }}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                Bed Booking
              </Button>
            </>
          )}
        </Stack>
      </Stack>

      <Stack gap={4}>
        {selectedService !== 'bedBooking' && (
          <Stack
            className="dbutton"
            direction={'row'}
            alignItems={'center'}
            gap={'20px'}
          >
            {type === 'patient' && (
              <Button
                bgGradient={
                  selectedService === 'pending' &&
                  'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={
                  selectedService === 'pending' ? '#fff' : 'primaryBlack.100'
                }
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => setSelectedService('pending')}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                Requested
              </Button>
            )}

            {type === 'hospital' && (
              <Button
                bgGradient={
                  selectedService === 'pending' &&
                  'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={
                  selectedService === 'pending' ? '#fff' : 'primaryBlack.100'
                }
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => setSelectedService('pending')}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                Pending
              </Button>
            )}
            {type !== 'patient' && (
              <Button
                bgGradient={
                  selectedService === 'ongoing' &&
                  'linear(to-r, #295377, #208C74)'
                }
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={
                  selectedService === 'ongoing' ? '#fff' : 'primaryBlack.100'
                }
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                onClick={() => setSelectedService('ongoing')}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                Ongoing
              </Button>
            )}
            <Button
              bgGradient={
                selectedService === 'completed' &&
                'linear(to-r, #295377, #208C74)'
              }
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              color={
                selectedService === 'completed' ? '#fff' : 'primaryBlack.100'
              }
              borderRadius={'25px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              onClick={() => setSelectedService('completed')}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
            >
              Completed
            </Button>
            <Button
              bgGradient={
                selectedService === 'cancel' && 'linear(to-r, #295377, #208C74)'
              }
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              color={selectedService === 'cancel' ? '#fff' : 'primaryBlack.100'}
              borderRadius={'25px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              onClick={() => setSelectedService('cancel')}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
            >
              Cancel
            </Button>
          </Stack>
        )}

        {(isLoading || !appointments) ? (
          <Spinner />
        ) : (
          <>
            {selectedService === 'bedBooking' ? (
             <PatBooking data={appointments?.bedBooking} />
            ) : (
              <AppointmentBox
                type={type}
                selectedService={selectedService}
                data={appointments[selectedService] || []}
                fetchAppointments={fetchAppointments}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default DashboardAppointments;

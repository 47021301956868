import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridItem,
  Box,
  HStack,
  Stack,
  Avatar,
  Heading,
  Text,
  WrapItem,
  Button,
  Divider,
  Badge,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';

function DocInfoBox() {
  const [doctorsList, setDoctorsList] = useState(null);

  async function fetchDoctors() {
    try {
      const response = await GET('/hospital/practitioners');
      console.log(response);
      if (response.status === 200) setDoctorsList(response.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchDoctors();
  }, []);

  return (
    <Grid templateColumns="repeat(2, 1fr)" alignItems={'center'} gap={6}>
      {!doctorsList ? (
        <Spinner />
      ) : doctorsList.length > 0 ? (
        doctorsList?.map((val, ind) => (
          <Box
            key={ind}
            shadow={'lg'}
            w="100%"
            borderRadius={'16px'}
            bg={'#fff'}
            p={5}
          >
            <HStack alignItems={'center'}>
              <WrapItem>
                <Avatar
                  size="lg"
                  name="Prosper Otemuyiwa"
                  src={`${imgUrl}/${val?.doctor?.photo}`}
                />{' '}
              </WrapItem>
              <Stack justifyContent={'center'}>
                <Heading fontWeight={'400'} fontSize={'22px'}>
                  {val?.doctor?.name}
                </Heading>
                <Text
                  lineHeight={0}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'#75767A'}
                >
                  {val?.patient ? `#${val?._id.slice(-5)}` : ''}
                </Text>
              </Stack>
            </HStack>
            {val?.patient ? (
              <>
                <Heading
                  py="15px"
                  fontSize={'16px'}
                  fontWeight={'600'}
                  color={'primaryBlack.100'}
                >
                  Upcoming Appointments
                </Heading>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  flexWrap={'wrap'}
                  alignItems={'flex-start'}
                  spacing={0}
                >
                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Appointment Date
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(val.startTime).format('M/D/YYYY') || '2/27/2024'}
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Type
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {val.type}
                    </Text>
                  </Stack>

                  <Stack h="65px" mb="10px !important" w="32%">
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Patient
                    </Text>

                    <HStack marginTop={'2px'}>
                      <Avatar
                        size="sm"
                        name="Kent Dodds"
                        src={`${imgUrl}/${val?.patient?.picture}`}
                      />{' '}
                      <Text
                        lineHeight={'5px'}
                        fontWeight={'400'}
                        color={'#1B1C1D'}
                      >
                        {val?.patient?.fullName}
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      $Price
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      ${val?.doctor?.charges}
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Time
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(val.startTime).format('h - mm A') || ' 3 - 30 PM'}
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Age
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {val.patient.age || '--'}
                    </Text>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                <Heading
                  py="15px"
                  fontSize={'16px'}
                  fontWeight={'600'}
                  color={'primaryBlack.100'}
                >
                  No Upcoming Appointments
                </Heading>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  flexWrap={'wrap'}
                  alignItems={'flex-start'}
                  spacing={0}
                >
                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Appointment Date
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      --
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Type
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      -
                    </Text>
                  </Stack>

                  <Stack h="65px" mb="10px !important" w="32%">
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Patient
                    </Text>

                    <HStack marginTop={'2px'}>
                      {/* <Avatar
                        size="sm"
                        name="Kent Dodds"
                        src={`${imgUrl}/${val?.patient?.picture}`}
                      />{' '} */}
                      <Text
                        lineHeight={'5px'}
                        fontWeight={'400'}
                        color={'#1B1C1D'}
                      >
                        --
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      $Price
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      --
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Time
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      --
                    </Text>
                  </Stack>

                  <Stack
                    h="65px"
                    mb="10px !important"
                    w="32%"
                    margin={'0 !important'}
                  >
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Age
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      --
                    </Text>
                  </Stack>
                </Stack>
              </>
            )}
            <Button
              as={Link}
              to={`/dashboard/doctor-profile/${val?.doctor?._id}`}
              mt="30px"
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              w="100%"
              borderRadius={'25px'}
              h="45px"
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              View Profile
            </Button>
          </Box>
        ))
      ) : (
        <Heading>No Health practitioners</Heading>
      )}
    </Grid>
  );
}

export default DocInfoBox;
